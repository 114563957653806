// import React from 'react'
import { range } from 'lodash'
// import { Globe, DollarSign, Heart, Home, HelpCircle, Sunset } from 'react-feather'

export const RISK_ASSESSMENT_QUESTION_IDs = {
  inflating_expenses: 'inflating_expenses'
}

export default {
  transition: {
    icon: '',
    // icon: <Globe size={20} />,
    title: 'Transition',
    color: 'orange',
    questions: [
      {
        id: 'the_salary_cliff',
        cardText: "I'm concerned about the sizable income drop-off at retirement.",
        title: 'Loss of Income',
        accordionText:
          'After living on a monthly income for our entire life, transitioning from a dependable monthly cash flow into an uncertain distribution from savings can be shocking.',
        cardImage: '/images/steps_icon/steps_icon_1.svg'
      },
      {
        id: 'retirement_income_shock',
        cardText: 'One spouse is still working, which could hinder our plans for early retirement.',
        title: 'Salary Dependence',
        accordionText:
          'After a spouse retires, the other spouse often continues to work to provide the household with a stable income. This salary reduction may result in impacting some of the most active retirement years.',
        cardImage: '/images/steps_icon/steps_icon_2.svg'
      },
      {
        id: 'early_retirement_income_shortfall',
        cardText:
          "Do I have to wait to retire? My Social Security income doesn't start for another few years.",
        title: 'Early Retirement Income Shortfall',
        accordionText:
          'Often, there are large income deficits in the early retirement years. This is due to the age-based timing of income sources; including Social Security, pensions, etc.',
        cardImage: '/images/steps_icon/steps_icon_3.svg'
      },
      {
        id: 'cancelled_plans',
        cardText:
          "I'm worried about having to put off plans, such as travel, to make sure I'm not overspending.",
        title: 'Cancelled Plans', // 'murica
        accordionText:
          "After experiencing the early years' income deficits, we tend to cut spending on things we hoped to enjoy, such as travel. This may put these goals at risk; while we may be able to afford to spend later in retirement, our health and other factors may be a hinderance.",
        cardImage: '/images/steps_icon/steps_icon_4.svg'
      }
    ]
  },
  economy: {
    icon: '',
    // icon: <DollarSign size={20} />,
    title: 'Economic Risk',
    color: 'purple',
    questions: [
      {
        cardText:
          'I need to plan for potential economic changes, such as tax increases or market volatility.',
        id: 'economic_uncertainty',
        title: 'Economic Uncertainty',
        accordionText:
          'Economic factors can weigh heavily on our retirement decisions. Tax changes, market volatility, and other uncertainty can lead to concerns over running out of savings.',
        cardImage: '/images/steps_icon/steps_icon_5.svg'
      },
      {
        cardText: 'With so many unknowns, I find myself worried that I may run out of money later.',
        id: 'running_out_of_money',
        title: 'Running Out of Money',
        accordionText:
          'Running out of money is consistently one of the main concerns for retirees, and it can affect all the other aspects of retirement planning.',
        cardImage: '/images/steps_icon/steps_icon_6.svg'
      },
      {
        cardText:
          "I may have enough for today, but I'm concerned how inflation will impact my spending power later on.",
        id: RISK_ASSESSMENT_QUESTION_IDs.inflating_expenses,
        title: 'Rising Expenses (Inflation)',
        accordionText:
          'Inflation is ever-present in retirement. Something as simple as going to to the store to buy a gallon of milk can remind us of constant price increases while our income is either uncertain or fixed.',
        cardImage: '/images/steps_icon/steps_icon_7.svg',
        disclaimers: [
          'https://www.ssa.gov/OACT/COLA/colaseries.html',
          'https://www.morningstar.com/content/dam/marketing/shared/research/foundational/677785-EstimatingTrueCostRetirement.pdf'
        ]
      }
    ]
  },
  uncertainty: {
    icon: '',
    // icon: <HelpCircle size={20} />,
    title: 'Uncertainty',
    color: 'green',
    questions: [
      {
        id: 'emergency_fund_for_unknown_expenses',
        cardText:
          "I'd like to make sure I maintain an emergency fund, and possibly continue adding to it.",
        title: 'Emergency Fund for Unknown Expenses',
        accordionText:
          'Many hold the belief that saving into a substantial and protected emergency fund can be vital to the long term sustainability of our retirement. Monthly saving shouldn’t stop at retirement.',
        cardImage: '/images/steps_icon/steps_icon_8.svg'
      },
      {
        id: 'kids_and_grandkids',
        cardText: "I'm concerned that I may need to help support my adult child or grandchildren.",
        title: 'Helping Kids and Grandkids',
        accordionText:
          'Caring for adult children & grandchildren can be an unexpected retirement expense, which can place retirement plans in jeopardy.',
        cardImage: '/images/steps_icon/steps_icon_9.svg',
        expenses: range(85, 88).map(age => {
          return {
            age,
            yearlyExpenseChange: 5000
          }
        }),
        disclaimers: [
          'https://www.fool.com/retirement/2018/12/07/heres-how-much-it-costs-parents-to-have-adult-kids.aspx',
          'https://money.com/so-your-adult-child-moved-back-home-heres-how-to-set-limits-fast/'
        ]
      },
      {
        id: 'aging_parents',
        cardText:
          'I have a parent/parents that may need assistance, requiring additional time or financial commitment.',
        title: 'Caring for Aging Parents',
        accordionText:
          'Our parents are living longer, and while this is a great blessing, helping financially can be a difficult unexpected expense, especially for early retirees.',
        cardImage: '/images/steps_icon/steps_icon_10.svg',
        expenses: range(70, 73).map(age => {
          return {
            age,
            yearlyExpenseChange: 5000
          }
        })
      }
    ]
  },
  healthcare: {
    icon: '',
    // icon: <Heart size={20} />,
    title: 'Healthcare',
    color: 'blue',
    questions: [
      {
        id: 'healthcare_costs_age_65_and_under',
        cardText:
          "I'm concerned about how I should plan for healthcare costs before I reach age 65.",
        title: 'Healthcare Costs Up to Age 65',
        expenses: range(1, 64).map(age => ({
          age,
          yearlyExpenseChange: 800
        })),
        accordionText:
          'Retiring early can present some unique healthcare challenges. Before age 65, we need careful planning top avoid the potential of extremely high healthcare costs.',
        cardImage: '/images/steps_icon/steps_icon_11.svg',
        disclaimers: [
          'https://www.kiplinger.com/article/retirement/T027-C000-S004-finding-health-insurance-before-medicare.html',
          'https://www.fidelity.com/viewpoints/retirement/transition-to-medicare',
          'https://money.usnews.com/money/retirement/medicare/articles/health-insurance-options-when-retiring-early'
        ]
      },
      {
        id: 'medical_expenses_after_age_65_for_you_or_your_spouse',
        cardText:
          'After age 65, I want to be prepared for healthcare costs not covered by Medicare.',
        title: 'Healthcare Costs After Age 65',
        expenses: [
          {
            age: 66,
            yearlyExpenseChange: 3500
          },
          {
            age: 83,
            yearlyExpenseChange: 3500
          },
          {
            age: 100,
            yearlyExpenseChange: 3500
          }
        ],
        accordionText:
          'While Medicare premiums should be manageable, medical emergencies can carry out of pocket maximums as high as $7,000-$10,000.',
        cardImage: '/images/steps_icon/steps_icon_12.svg',
        disclaimers: [
          'https://www.forbes.com/sites/dianeomdahl/2019/01/24/medigap-plan-f-or-plan-g/#497996c53cc3',
          'https://www.aarpsupplementalhealth.com/content/dam/ole/MedSuppDocs/BenefitsTable/StatePlanVariations/FLOOC_0120_wc1.pdf',
          'https://www.aarpmedicaresupplement.com/content/dam/aarpmedicaresupplement/aarp-pdfs/ahead-pdf/WB26150ST%20(03-19)_AHD.pdf',
          'https://www.nerdwallet.com/blog/investing/what-will-you-spend-on-health-care-costs-in-retirement/',
          'https://www.investopedia.com/retirement/how-plan-medical-expenses-retirement/',
          'https://crr.bc.edu/wp-content/uploads/2017/10/wp_2017-13.pdf'
        ]
      },
      {
        id: 'uncovered_prescription_costs',
        cardText:
          "I want to make sure I have enough to afford any needed prescriptions that aren't covered by Medicare.",
        title: 'Uncovered Prescription Costs',
        expenses: range(65, 101).map(age => ({
          age,
          yearlyExpenseChange: 1200
        })),
        accordionText:
          "A complete retirement plan may need to include setting money aside for uncovered prescriptions. When the prescriptions we need aren't covered, the costs can wear down our savings.",
        cardImage: '/images/steps_icon/steps_icon_13.svg',
        disclaimers: [
          'https://money.cnn.com/retirement/guide/insurance_health.moneymag/index17.htm#:~:text=Medicare%20Part%20D%20provides%20prescription,buy%20from%20a%20private%20insurer.&text=Once%20you%20cover%20the%20deductible,total%20drug%20costs%20per%20year',
          'https://www.investopedia.com/retirement/how-plan-medical-expenses-retirement/#citation-17'
        ]
      },
      {
        id: 'dental_care',
        cardText: 'I may need to prepare for potentially large dental care costs.',
        title: 'Dental Care',
        expenses: [
          {
            age: 78,
            yearlyExpenseChange: 10000
          }
        ],
        accordionText:
          'Dental care costs are rapidly increasing, and this is one of the most common unplanned expenses in retirement. This can potentially decrease your savings by tens of thousands of dollars.',
        cardImage: '/images/steps_icon/steps_icon_14.svg',
        disclaimers: [
          'https://southlanddentalcare.com/full-mouth-dental-implants-cost-los-angeles/',
          'https://www.bankrate.com/finance/insurance/dental-insurance-1.aspx#:~:text=People%20with%20dental%20insurance%20commonly,for%20crowns%2C%20bridges%20and%20major'
        ]
      }
    ]
  },
  home: {
    icon: '',
    // icon: <Home size={20} />,
    title: 'Home',
    color: 'red',
    questions: [
      {
        id: 'home_maintenance_and_repairs',
        cardText: 'I anticipate some large home maintenance costs at some point in retirement.',
        title: 'Home Maintenance and Repairs',
        accordionText:
          'Unfortunately, our homes age and need constant maintenance. New roofs, air-conditioners, and renovations are just a few of the larger lump sum costs that should be considered during retirement.',
        expenses: [
          {
            age: 72,
            yearlyExpenseChange: 5000
          },
          {
            age: 78,
            yearlyExpenseChange: 5000
          },
          {
            age: 84,
            yearlyExpenseChange: 5000
          },
          {
            age: 90,
            yearlyExpenseChange: 5000
          },
          {
            age: 97,
            yearlyExpenseChange: 5000
          }
        ],
        cardImage: '/images/steps_icon/steps_icon_15.svg',
        disclaimers: [
          'https://www.angieslist.com/articles/how-much-does-roof-replacement-cost.htm#:~:text=How%20Much%20Do%20Roofing%20Materials,is%20between%20%246.37%20and%20%248.77',
          'https://www.homeadvisor.com/cost/roofing/install-a-roof/',
          'https://roofadvisor.com/how-often-should-you-replace-your-roof/#:~:text=Composition%20Shingles%3A%2012%2D20%20years,Rubber%20Roofs%3A%2030%2D50%20years',
          'https://www.pickhvac.com/central-air-conditioner/replacement-cost/',
          'https://www.cousinsair.com/blog/2017/may/how-long-do-air-conditioners-last-in-florida-/#:~:text=Many%20professionals%20say%20that%2C%20roughly,and%20subject%20to%20weather%20extremes'
        ]
      }
    ]
  },
  aging: {
    icon: '',
    // icon: <Sunset size={20} />,
    title: 'Aging',
    color: 'cyan',
    questions: [
      {
        id: 'loss_of_a_spouse',
        cardText: 'I want to have a financial plan in place in case a spouse passes.',
        title: 'Loss of a Spouse',
        accordionText:
          'Unfortunately, losing a spouse is likely to result in an income loss. Plans should be made for decreases in social security payments, pensions, and other income sources.',
        cardImage: '/images/steps_icon/steps_icon_16.svg'
      },
      {
        id: 'aging_in_place',
        cardText:
          'Besides healthcare, I want to plan for other costs associated with aging in place.',
        title: 'Aging at Home',
        expenses: [
          {
            age: 90,
            yearlyExpenseChange: 7500
          }
        ],
        accordionText:
          "It's hard to fathom having to leave the security of our homes in our later years. The costs associated with preparing our home for aging in place can be a higher than expected.",
        cardImage: '/images/steps_icon/steps_icon_17.svg',
        disclaimers: [
          'https://www.homeadvisor.com/r/moving-in-aging-or-disabled-parent/',
          'https://www.kompareit.com/homeandgarden/aginganddisability-retrofit-to-wheelchair.html#:~:text=Complete%20bathroom%20retrofit%3A%20%2415%2C000%20to,permanent%20ramp%3A%20%241%2C500%20to%20%2415%2C000'
        ]
      },
      {
        id: 'long_term_care',
        cardText: 'I am concerned about the potential cost of Long-Term Care.',
        title: 'Long-Term Care',
        accordionText:
          'Long-Term Care prices have, historically, steadily increased. We should assume this trend will continue. Current national averages are approximately $7,000 per month.',
        expenses: range(90, 93).map(age => {
          return {
            age,
            yearlyExpenseChange: 84000
          }
        }),
        cardImage: '/images/steps_icon/steps_icon_18.svg',
        disclaimers: [
          'https://longtermcare.acl.gov/costs-how-to-pay/costs-of-care.html#:~:text=Below%20are%20some%20national%20average,room%20in%20a%20nursing%20home',
          'http://www.caregiverlist.com/SeniorCareCosts.aspx',
          'https://www.genworth.com/aging-and-you/finances/cost-of-care.html'
        ]
      },
      {
        id: 'home_healthcare_skilled_or_unskilled',
        cardText: 'I plan on staying home as long as possible, with unskilled healthcare help.',
        title: 'Home Healthcare',
        accordionText:
          'Aging at home can be possible up to a point. Planning for this unskilled help should include costs in the range of $3,000/month.',
        expenses: range(90, 93).map(age => {
          return {
            age,
            yearlyExpenseChange: 36000
          }
        }),

        cardImage: '/images/steps_icon/steps_icon_19.svg',
        expenseOverrides: ['long_term_care'],
        disclaimers: [
          'https://longtermcare.acl.gov/costs-how-to-pay/costs-of-care.html#:~:text=Below%20are%20some%20national%20average,room%20in%20a%20nursing%20home'
        ]
      },
      {
        id: 'heirs',
        cardText:
          'I want my retirement income plan to include leaving money for kids, grandkids, and/or charities.',
        title: 'Heirs',
        accordionText:
          'Many retirees desire to leave a financial legacy for the next generations. This can sometimes result in affecting the enjoyment of our own retirement. While this may not be a risk to your finances, it can be a risk to your abillty to live the retirement that you hoped for.',
        cardImage: '/images/steps_icon/steps_icon_20.svg'
      }
    ]
  }
}
