<template>
<div class="page_content_wrap">
	<div class="results_wrap py-5">
		<div class="container">
			<div class="results_box" v-if="!isLoading && getUser">
				<h5 class="text-primary mb-4">
	               Thanks, {{ getUser.name }}! <span class="font-size-lg">👍</span>
	            </h5>
	            <h2 class="text-dark mb-2">
	               Get ready to view your results.
	            </h2>
	            <p class="text-dark font-size-lg mb-5">Review your answers and make adjustments as necessary.</p>

	            <div class="results_box_details font-size-lg mb-5">
	            	<h3 class="text-dark mb-4"><i class="fas fa-id-card pr-1"></i> Personal Details 
						<router-link :to="{ name:'risk.surveyBio', query: {'from-review': true}}" class="font-size-sm font-weight-normal text-primary pl-2"><i class="fal fa-edit pr-1"></i> Edit</router-link>
					</h3>
	            	<div class="results_box_icons border-bottom border-gray-200 py-2 mb-2" :data-key="question.key" :key="question.key" v-for="question in personalDetailQuestionsList">
	            		<div class="row">
	            			<div class="col-md-8 col-12">
	            				<div class="text-dark">{{ question.question }}</div>
	            			</div>
	            			<div class="col-md-4 col-12 mt-2 mt-md-0">
	            				<div class="text-dark font-weight-bold">{{ question.answer }}</div>
	            			</div>
	            		</div>
	            	</div>
	            </div>
	            <div class="results_box_details font-size-lg mb-5">
	            	<h3 class="text-dark mb-4"><i class="fas fa-chart-line-down pr-1"></i> Risk Assessment 
						<router-link :to="{ name:'risk.slider', query: {'from-review': true}}" class="font-size-sm font-weight-normal text-primary pl-2"><i class="fal fa-edit pr-1"></i> Edit</router-link>
					</h3>
	            	<div class="results_box_icons border-bottom border-gray-200 py-2 mb-2" :data-key="question.key" :key="question.key" v-for="question in risksList">
	            		<div class="row">
										<div class="col-1 text-center">
	            				<div class="text-dark font-weight-bold border">
												<i class="fal fa-times text-danger" v-if="!question.answer"></i> <i class="fal fa-check text-success" v-if="question.answer"></i> 
											</div>
	            			</div>
	            			<div class="col-11">
	            				<div class="text-dark">{{ question.question }}</div>
	            			</div>
	            		</div>
	            	</div>
	            </div>
	            <div class="results_box_details font-size-lg mb-5">
	            	<h3 class="text-dark mb-4"><i class="fas fa-coins pr-1"></i> Annual Disposable Income 
						<router-link :to="{ name: 'risk.disposableIncome', query: {'from-review': true}}"  class="font-size-sm font-weight-normal text-primary pl-2"><i class="fal fa-edit pr-1"></i> Edit</router-link>
					</h3>
	            	<div class="results_box_icons border-bottom border-gray-200 py-2 mb-2" :data-key="question.key" :key="question.key" v-for="question in disposeIncomeList">
	            		<div class="row">
	            			<div class="col-6 col-sm-8">
	            				<div class="text-dark">{{ question.question }}</div>
	            			</div>
	            			<div class="col-6 col-sm-4">
	            				<div class="text-dark font-weight-bold text-right">{{ question.answer }}</div>
	            			</div>
	            		</div>
	            	</div>
	            </div>
	            <div class="results_box_details font-size-lg mb-5">
	            	<h3 class="text-dark mb-4"><i class="fas fa-hiking pr-1"></i> Fixed Income In Retirement 
						<router-link :to="{ name :'risk.fixedIncome', query: {'from-review': true}}" class="font-size-sm font-weight-normal text-primary pl-2"><i class="fal fa-edit pr-1"></i> Edit</router-link>
					</h3>
	            	<div class="results_box_icons border-bottom border-gray-200 py-2 mb-2" :data-key="question.key" :key="question.key" v-for="question in fixedIncomeList">
	            		<div class="row">
	            			<div class="col-6 col-sm-8">
	            				<div class="text-dark">{{ question.question }}</div>
	            			</div>
	            			<div class="col-6 col-sm-4">
	            				<div class="text-dark font-weight-bold text-right">{{ question.answer }}</div>
	            			</div>
	            		</div>
	            	</div>
	            </div>
	            <div class="results_box_details font-size-lg mb-5">
	            	<h3 class="text-dark mb-4"><i class="fas fa-calendar-alt pr-1"></i> Annual Expenses 
						<router-link :to="{ name: 'risk.monthlyExpence', query: {'from-review': true}}" class="font-size-sm font-weight-normal text-primary pl-2"><i class="fal fa-edit pr-1"></i> Edit</router-link>
					</h3>
	            	<div class="results_box_icons border-bottom border-gray-200 py-2 mb-2" :data-key="question.key" :key="question.key" v-for="question in expencesList">
	            		<div class="row">
	            			<div class="col-6 col-sm-8">
	            				<div class="text-dark">{{ question.question }}</div>
	            			</div>
	            			<div class="col-6 col-sm-4">
	            				<div class="text-dark font-weight-bold text-right">{{ question.answer }}</div>
	            			</div>
	            		</div>
	            	</div>
	            </div>
	            <div class="btn_continue_wrap">
	            	<a href="#" class="btn btn-primary btn-lg btn-block mt-2" @click.prevent="nextPage">VIEW YOUR RESULT <i class="fas fa-arrow-right pl-1"></i></a>
	            </div>
			</div>
			<div class="loader" v-else></div>
		</div>
	</div>
</div>
</template>


<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getContact } from "@/utils/firebase";
import PERSONAL_DETAIL_QUESTIONS from '@/data/personal-details-questions'
import CARD_QUESTIONS from '@/data/risk-assessment-categories'
import {
  FROM_REVIEW_PARAM_KEY,
  HUBSPOT_DISPOSABLE_INCOME_LIST_KEY,
  HUBSPOT_EXPENSES_LIST_KEY,
  HUBSPOT_FIXED_INCOME_LIST_KEY
} from '@/utils/constants'
import { parse } from "query-string";

const cardData = Object.keys(CARD_QUESTIONS)
  .map(category =>
    CARD_QUESTIONS[category].questions.map(question => {
      return {
        content: question.cardText,
        id: question.id
      }
    })
  )
  .flat(1)

export default {
  name: 'Screen12',
  data() {
    return {
		isLoading: true,
		isSubmitting: false,
		errors: [],
		sectionsData: [],
		personalDetailQuestionsList: [],
		risksList: [],
		disposeIncomeList: [],
		fixedIncomeList: [],
		expencesList: []
    }
  },
  computed: {
    ...mapGetters("wizard", {
		survey: "getSurvey",
		expences: "getExpences",
		disposableIncomes: "getDisposableIncomes",
		fixedIncomes: "getFixedIncomes"
	}),
	...mapGetters({
      getUser: "getUser",
      getUserId: "getUserId"
    }),
  },
  mounted() {

	const img = new Image();
    img.src = '/images/step-animation.gif';

	this.isLoading = true;
    getContact([
		...Object.keys(PERSONAL_DETAIL_QUESTIONS),
		HUBSPOT_DISPOSABLE_INCOME_LIST_KEY,
		HUBSPOT_EXPENSES_LIST_KEY,
		HUBSPOT_FIXED_INCOME_LIST_KEY,
		...cardData.map(info => info.id)
	]).then((response) => {

		let prop = response;//.data.properties;
		// console.log("response.data.properties");
		// console.log(prop);
		
		//set Personal Detail
		this.personalDetailQuestionsList = Object.keys(PERSONAL_DETAIL_QUESTIONS).map(question => ({
          key: PERSONAL_DETAIL_QUESTIONS[question].id,
          question: PERSONAL_DETAIL_QUESTIONS[question].question,
          answer: prop[PERSONAL_DETAIL_QUESTIONS[question].id]
		}));
		this.SET_SURVEY(prop);
		// console.log('personalDetailQuestionsList');
		// console.log(this.personalDetailQuestionsList);

		//set Risk List
		this.risksList = cardData
		.map(info => {
		if (!prop[info.id]) {
			return null
		}

		return {
			key: info.id,
			question: info.content,
			answer: prop[info.id] == 'Applies to me' ? true : false
		}
		})
		.filter(Boolean);
		// console.log('risksList');
		// console.log(this.risksList);
		  
		//set disposable income list
		const disposableIncome = prop[HUBSPOT_DISPOSABLE_INCOME_LIST_KEY] ? JSON.parse(prop[HUBSPOT_DISPOSABLE_INCOME_LIST_KEY]) : [];
		this.SET_DISPOSABLE_INCOME(parsedExpence);

		if (disposableIncome.length) {
			this.disposeIncomeList = disposableIncome.map((item, idx) => {
				return {
					key: idx,
					question: item.title,
					answer: `$${item.value.toLocaleString()} / ${item.timeFrame}`
				}
			})
		}

		//set fixed income list
		const fixedIncomes = prop[HUBSPOT_FIXED_INCOME_LIST_KEY] ? JSON.parse(prop[HUBSPOT_FIXED_INCOME_LIST_KEY]) : [];
		this.SET_FIXED_INCOMES(parsedExpence);

		if (fixedIncomes.length) {
        	this.fixedIncomeList = fixedIncomes.map((item, idx) => {
				return {
					key: idx,
					question: item.title,
					answer: `$${item.value.toLocaleString()} / ${item.timeFrame}`
				}
			});
		}

		//set expence list
		const parsedExpence = prop[HUBSPOT_EXPENSES_LIST_KEY] ? JSON.parse(prop[HUBSPOT_EXPENSES_LIST_KEY]) : [];
		this.SET_EXPENCES(parsedExpence);

		if (parsedExpence.length) {
        	this.expencesList = parsedExpence.map((item, idx) => {
				return {
					key: idx,
					question: item.title,
					answer: `$${item.value.toLocaleString()} / ${item.timeFrame}`
				}
			})
		}

        this.isLoading = false;
	})
	.catch((error) => {
		console.log("error");
		console.log(error);
		this.isLoading = false;
	});
	
  },
   methods: {
	...mapActions("wizard", {
      updateProgress: "updateProgress",
	}),
	nextPage() {
		
		let userObj = {
			riskLink: 'risk.results'
		};

      	this.updateProgress({ step: 1, data: userObj });

		if(this.$device.ios || this.$device.android) {
			this.$router.push({ name : 'risk.results'});
		} else {
			this.$router.push({ name : 'risk.reviewLoader'});
		}
	},
	...mapMutations("wizard", ["SET_SURVEY", "SET_EXPENCES", "SET_DISPOSABLE_INCOME", "SET_FIXED_INCOMES"])
  }
};
</script>
